@font-face {
    font-family: "Bebas Neue";
    src: local("BebasNeue"),
      url("../public/fonts/BebasNeue.ttf") format("truetype");
  }

  @font-face {
    font-family: "Roboto";
    src: local("RobotoCondensed-Regular"),
      url("../public/fonts/RobotoCondensed-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "RobotoBold";
    src: local("RobotoCondensed-Bold"),
      url("../public/fonts/RobotoCondensed-Bold.ttf") format("truetype");
  }


.container {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Roboto", sans-serif;
}

h1 {
    font-family: "Bebas Neue", sans-serif;
    font-size: 2.5em;
    color: #FFFFFF;
    text-align: center;
    padding-bottom: 10px;
    margin: 0;
    padding-top: 20px;
    font-weight: 400;
}

h2 {
    font-family: "RobotoBold", sans-serif;
    text-align: center;
}

h3 {
    font-family: "RobotoBold", sans-serif;
    text-align: center;
}
.email-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    box-sizing: border-box;
}
.delete-button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #FF0000;
    color: #FFFFFF;
    font-family: "RobotoBold", sans-serif;
    font-size: 1em;
    border: none;
    cursor: pointer;
}
.error {
    color: #FF0000;
    font-family: "Roboto", sans-serif;
    font-size: 0.8em;
    margin: 0;
    padding: 0;
    text-align: center;
}
